<template>
    <div class="goodbye">
        <BlogPosts />
    </div>
</template>

<script>
import BlogPosts from '@/components/BlogPosts.vue';

export default {
    name: 'Goodbye',
    components: { BlogPosts },
    metaInfo() {
        return {
            title: this.$t('goodbye.title'),
        };
    },
};
</script>
