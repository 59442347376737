<template>
    <div class="password-reset-form">
        <div v-if="!result">
            <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(submit)">
                    <ValidationProvider v-slot="v" rules="required">
                        <div class="form-field form-field--direct-evaluation" :class="v.classes">
                            <input v-model="username" type="text">

                            <span class="form-field__label">{{ $t('passwordResetForm.username') }}*</span>
                            <span class="form-field__error">{{ v.errors[0] }}</span>
                        </div>
                    </ValidationProvider>

                    <button type="submit" class="button button--arrow" :class="{ 'button--loading': loading }" :disabled="loading">{{ $t('passwordResetForm.button') }}</button>
                </form>
            </ValidationObserver>
        </div>
        <div v-else>
            {{ $t('passwordResetForm.confirmation') }}
        </div>
    </div>
</template>

<script>
import axios from '@/config/axios';

export default {
    name: 'PasswordResetForm',

    data: () => ({
        username: '',

        loading: false,
        result: false,
    }),

    methods: {
        submit() {
            this.loading = true;

            axios({
                url: '/api/v2/reset-password',
                method: 'POST',
                data: {
                    username: this.username,
                    host: `${window.location.origin}/login/password-reset/set-password`,
                },
            })
                .then(() => {
                    this.loading = false;
                    this.result = true;
                });
        },
    },
};
</script>
