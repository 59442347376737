<template>
    <div class="password-reset-form">
        <p style="color: red" v-if="error" v-html="$t('passwordResetSetPasswordForm.error')"></p>

        <div v-if="!result">
            <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(submit)">
                    <ValidationProvider v-slot="v" rules="required" vid="password">
                        <div class="form-field form-field--direct-evaluation" :class="v.classes">
                            <input v-model="password" type="password">

                            <span class="form-field__label">{{ $t('passwordResetSetPasswordForm.password') }}*</span>
                            <span class="form-field__error">{{ v.errors[0] }}</span>
                        </div>
                    </ValidationProvider>

                    <ValidationProvider v-slot="v" rules="required|confirmed:password">
                        <div class="form-field form-field--direct-evaluation" :class="v.classes">
                            <input v-model="passwordRepeat" type="password">

                            <span class="form-field__label">{{ $t('passwordResetSetPasswordForm.passwordRepeat') }}*</span>
                            <span class="form-field__error">{{ v.errors[0] }}</span>
                        </div>
                    </ValidationProvider>

                    <button type="submit" class="button button--arrow" :class="{ 'button--loading': loading }" :disabled="loading">{{ $t('passwordResetSetPasswordForm.button') }}</button>
                </form>
            </ValidationObserver>
        </div>
        <div v-else>
            <p>{{ $t('passwordResetSetPasswordForm.confirmation') }}</p>

            <router-link :to="{ name: 'Login' }" class="button button--arrow">{{ $t('passwordResetSetPasswordForm.loginButton') }}</router-link>
        </div>
    </div>
</template>

<script>
import axios from '@/config/axios';

export default {
    name: 'PasswordResetSetPasswordForm',

    data: () => ({
        password: '',
        passwordRepeat: '',

        hash: null,
        loading: false,
        result: false,
        error: false,
    }),

    methods: {
        submit() {
            this.loading = true;

            axios({
                url: '/api/v2/change-password',
                method: 'POST',
                data: {
                    hash: this.hash,
                    new_password: {
                        first: this.password,
                        second: this.passwordRepeat,
                    },
                },
            })
                .then(() => {
                    this.loading = false;
                    this.result = true;
                })
                .catch(() => {
                    this.loading = false;
                    this.result = false;
                    this.error = true;
                });
        },
    },

    mounted() {
        this.hash = this.$route.params.hash;
    },
};
</script>
