<template>
    <div class="login-form">
        <p style="color: red" v-if="authStatus === 'error'">{{ $t('loginForm.error') }}</p>

        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)">
                <ValidationProvider v-slot="v" rules="required">
                    <div class="form-field form-field--direct-evaluation" :class="v.classes">
                        <input v-model="username" type="text">

                        <span class="form-field__label">{{ $t('loginForm.username') }}*</span>
                        <span class="form-field__error">{{ v.errors[0] }}</span>
                    </div>
                </ValidationProvider>

                <ValidationProvider v-slot="v" rules="required">
                    <div class="form-field form-field--has-suffix form-field--direct-evaluation" :class="v.classes">
                        <input v-model="password" type="password" ref="passwordField">

                        <router-link :to="{ name: 'PasswordReset' }" class="form-field__action">{{ $t('loginForm.forgotPassword') }}</router-link>
                        <span class="form-field__suffix form-field__suffix--eye" @click="togglePasswordType()"></span>
                        <span class="form-field__label">{{ $t('loginForm.password') }}*</span>
                        <span class="form-field__error">{{ v.errors[0] }}</span>
                    </div>
                </ValidationProvider>

                <div class="grid-x grid-margin-x">
                    <div class="cell small-24 medium-shrink">
                        <button type="submit" class="button button--arrow button--no-margin" :class="{ 'button--loading': loading }" :disabled="loading">{{ $t('loginForm.button') }}</button>
                    </div>
                </div>

                <hr>

                <div class="grid-x grid-margin-x">
                    <div class="cell small-24">
                        <a :href="$t('links.order')" class="button button--white button--small">{{ $t('loginForm.guestOrderButton') }}</a>
                        <router-link :to="{ name: 'RegisterUserStep' }" class="button button--white button--small">{{ $t('loginForm.registerButton') }}</router-link>
                    </div>
                </div>

            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import $ from 'jquery';
import { createNamespacedHelpers } from 'vuex';
import { AUTH_LOGIN, AUTH_GET_PROFILE } from '@/store/modules/security';

const { mapActions, mapGetters } = createNamespacedHelpers('security');

export default {
    name: 'LoginForm',

    data: () => ({
        username: '',
        password: '',

        loading: false,
    }),

    methods: {
        ...mapActions({
            checkLogin: AUTH_LOGIN,
            getProfile: AUTH_GET_PROFILE,
        }),

        submit() {
            this.loading = true;

            const { username, password } = this;

            this.checkLogin({ username, password }).then(() => {
                this.getProfile().then(() => {
                    this.loading = false;

                    this.$router.push('/');
                });
            }).catch(() => {
                this.loading = false;
            });
        },

        togglePasswordType() {
            const attr = $(this.$refs.passwordField).attr('type');

            $(this.$refs.passwordField).attr('type', attr === 'password' ? 'text' : 'password');
        },

    },

    computed: {
        ...mapGetters([
            'authStatus',
        ]),
    },
};
</script>
