<template>
    <div class="password-reset">
        <div class="grid-container">
            <div class="grid-x">
                <div class="cell small-24 medium-20 medium-offset-2 large-16 large-offset-4">
                    <h1 class="login__main-title" v-html="$t('login.mainTitle')"></h1>
                    <div class="card">
                        <h2 class="card__title">{{ $t('passwordResetSetPassword.title') }}</h2>

                        <PasswordResetSetPasswordForm />
                    </div>
                    <div class="login__footer" v-html="$t('login.footer')"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PasswordResetSetPasswordForm from '@/components/PasswordResetSetPasswordForm.vue';

export default {
    components: {
        PasswordResetSetPasswordForm,
    },

    metaInfo() {
        return {
            title: this.$t('passwordResetSetPassword.title'),
        };
    },
};
</script>
